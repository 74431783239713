import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ThoughtsContainer = styled.div`
  margin-top: 3.13rem;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 3rem;
`

const Thought = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(25% - 2rem);
  &:hover {
    cursor: pointer;
  }
  margin: 1rem 0;
  border: 1px solid #707070;
  height: 9.25rem;
  padding: 1rem;

  @media screen and (max-width: 1000px) {
    width: calc(33% - 2rem);
  }

  @media screen and (max-width: 768px) {
    width: calc(50% - 2rem);
  }
`

const Title = styled.span`
  margin-top: 0.72rem;
`

const ThoughtContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 1000;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  transition: opacity: 0.5s
  opacity: 1;

  @media screen and (max-width: 768px) {
    background-color: rgba(255, 255, 255, 1);
    margin-top: 100px;
  }
`

const ThoughtContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 960px;
`
const Control = styled.div`
  font-size: 30px;
  padding: 1rem;
`
const CompleteThought = styled.div`
  width: 50%;
  height: 75%;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  border: 1px solid #707070;

  @media screen and (max-width: 768px) {
    width: 80%;
    height: 50%;
  }
  overflow: scroll;
  padding: 1rem;

  ${Title} {
    margin: 1.5rem 0;
  }

  p {
    line-height: 1.25rem;
    padding: 0.5rem 0;
  }
`
const ThoughtsPage = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(null)
  const [currentThought, setCurrentThought] = useState(null)
  const thoughts = data.allContentfulThoughts.nodes

  const goPrev = (e) => {
    e.stopPropagation()
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    } else {
      setCurrentIndex(thoughts.length - 1)
    }
  }
  const goNext = (e) => {
    e.stopPropagation()
    if (currentIndex < thoughts.length - 1) {
      setCurrentIndex(currentIndex + 1)
    } else {
      setCurrentIndex(0)
    }
  }

  useEffect(() => {
    setCurrentThought(thoughts[currentIndex])
  }, [currentIndex, setCurrentThought])

  return (
    <Layout>
      <SEO title="Motion" />
      <ThoughtsContainer>
        {thoughts.map((video, index) => (
          <Thought onClick={() => setCurrentIndex(index)}>
            {video.date}
            <Title>{video.title}</Title>
            <p>{video.body.childMarkdownRemark.excerpt}</p>
          </Thought>
        ))}
      </ThoughtsContainer>
      {!!currentThought && (
        <ThoughtContainer
          onClick={() => {
            setCurrentIndex(null)
            setCurrentThought(null)
          }}
        >
          <ThoughtContent>
            <Control onClick={goPrev}>&lt;</Control>
            <CompleteThought onClick={(e) => e.stopPropagation()}>
              {currentThought.date}
              <Title>{currentThought.title}</Title>
              <article
                dangerouslySetInnerHTML={{
                  __html: currentThought.body.childMarkdownRemark.html,
                }}
              ></article>
            </CompleteThought>
            <Control onClick={goNext}>&gt;</Control>
          </ThoughtContent>
        </ThoughtContainer>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ThoughtsQuery {
    allContentfulThoughts {
      nodes {
        body {
          childMarkdownRemark {
            excerpt
            html
          }
        }
        date(formatString: "MMM DD YYYY")
        title
      }
    }
  }
`

export default ThoughtsPage
